// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| APEXCHART ||============================== //
.print-header {
    display: none;
    width: 100%;
    text-align: center;
}

.print-header .header-title,
.print-header .header-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
}

@media print {
    .print-header {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        background: white;
    }

    .content {
        margin-top: 0px; /* Adjust this value as needed to push content below the header */
    }

    body {
        padding-top: 0px; /* Adjust this value as needed to push content below the header */
    }
}


.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: $primaryDark;
        /*background-color: transparent !important;*/
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: $primaryDark;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800');
.error-container {
    text-align: center;
    font-size: 106px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 800;
    margin: 70px 15px;
}
.error-container > span {
    display: inline-block;
    position: relative;
}
.error-container > span.four {
    width: 136px;
    height: 43px;
    border-radius: 999px;
    background: linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
        linear-gradient(
            105deg,
            transparent 0%,
            transparent 40%,
            rgba(0, 0, 0, 0.06) 41%,
            rgba(0, 0, 0, 0.07) 76%,
            transparent 77%,
            transparent 100%
        ),
        linear-gradient(to right, #d89ca4, #e27b7e);
}
.error-container > span.four:before,
.error-container > span.four:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 999px;
}
.error-container > span.four:before {
    width: 43px;
    height: 156px;
    left: 60px;
    bottom: -43px;
    background: linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
        linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
        linear-gradient(to top, #99749d, #b895ab, #cc9aa6, #d7969e, #e0787f);
}
.error-container > span.four:after {
    width: 137px;
    height: 43px;
    transform: rotate(-49.5deg);
    left: -18px;
    bottom: 36px;
    background: linear-gradient(to right, #99749d, #b895ab, #cc9aa6, #d7969e, #e0787f);
}

.error-container > span.zero {
    vertical-align: text-top;
    width: 156px;
    height: 156px;
    border-radius: 999px;
    background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%, transparent 51%, transparent 100%),
        linear-gradient(to top right, #99749d, #99749d, #b895ab, #cc9aa6, #d7969e, #ed8687, #ed8687);
    overflow: hidden;
    animation: bgshadow 5s infinite;
}
.error-container > span.zero:before {
    content: '';
    display: block;
    position: absolute;
    transform: rotate(45deg);
    width: 90px;
    height: 90px;
    background-color: transparent;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
        linear-gradient(
            85deg,
            transparent 0%,
            transparent 19%,
            rgba(0, 0, 0, 0.05) 20%,
            rgba(0, 0, 0, 0.07) 91%,
            transparent 92%,
            transparent 100%
        );
}
.error-container > span.zero:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 999px;
    width: 70px;
    height: 70px;
    left: 43px;
    bottom: 43px;
    background: #fdfaf5;
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}

@keyframes bgshadow {
    0% {
        box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
    }
    45% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
    55% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
    }
}

/* demo stuff */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    background-color: #fdfaf5;
    margin-bottom: 50px;
}
html,
button,
input,
select,
textarea {
    font-family: 'Montserrat', Helvetica, sans-serif;
    color: #bbb;
}
h1 {
    text-align: center;
    margin: 30px 15px;
}
.zoom-area {
    max-width: 490px;
    margin: 30px auto 30px;
    font-size: 19px;
    text-align: center;
}
.link-container {
    text-align: center;
}
a.more-link {
    text-transform: uppercase;
    font-size: 13px;
    background-color: #de7e85;
    padding: 10px 15px;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 50px;
    letter-spacing: 1px;
}

/************************/

// KNIFE SHARPENING STYLING

// .video,
// .wrap {
//   position: relative;
// }
// body {
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   justify-content: center;
//   user-select: none;
//   box-sizing: border-box;
//   position: fixed;
//   top: 0;
//   left: 0;
//   padding: 0;
//   width: 100%;
//   height: 100%;
//   min-height: 25rem;
//   font: 400 16px/1.4 -apple-system, BlinkMacSystemFont,"Segoe UI", Albert Sans, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
//   color: #fff;
//   background: #000;
// }
// h1 {
//   font-size: 1.875em;
//   font-weight: 400;
// }
// p {
//   margin: 1rem 0;
// }
// a {
//   color: #2f72e1;
//   border-bottom: 1px solid #2f72e1;
//   text-decoration: none;
//   transition: border 0.25s;
// }
// a:hover {
//   border-bottom-color: transparent;
// }
// .wrap {
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   box-sizing: border-box;
//   width: 40rem;
//   max-width: 100%;
// }
// .video {
//   display: block;
//   width: 90%;
//   height: auto;
//   margin-left: 10%;
//   z-index: -1;
// }
// .message {
//   display: block;
//   margin-top: -15%;
//   padding: 0 1rem;
//   text-align: left;
// }

// @media screen and (max-height: 460px) {
//     body {
//         position: absolute;
//     }
// }

// @media screen and (max-width: 479px) {
//   .message {
//     font-size: 0.8125rem;
//   }
// }

// @media screen and (max-width: 319px) {
//   h1 {
//     font-size: 1.25rem;
//   }
//   .message {
//     font-size: 0.75rem;
//   }
// }
